.table {
  &.amount {
    max-width: 500px;
    tr {
      td {
        min-width: 50px;
        text-align: center;
      }
    }
  }

  &.transports {
    & tbody td {
      text-align: center;
      &:first-child {
        text-align: left;
      }
    }
    & tfoot td {
      font-weight: bold;
      text-align: center;
      &:first-child {
        text-align: right;
      }
    }
  }
}
