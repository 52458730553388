.site-bar-menu {
  border-width: 1px 0;
  padding: 20px;
  box-shadow: 0 0.5em 1.5em rgb(0 0 0 / 10%), 0 0.125em 0.5em rgb(0 0 0 / 15%);
  border-radius: 5px;

  max-width: 300px;
  .badge-amount {
    margin: 0 10px;
  }
  .btn-toggle-nav a {
    padding: 0.1875rem 0.5rem;
    margin-top: 0.125rem;
    margin-left: 1.25rem;

    &:hover,
    &:focus, &.active {
      background-color: #d2f4ea;
    }
  }

  .active {
    padding: 0.1875rem 0.5rem;
    background-color: #d2f4ea;
  }
}
