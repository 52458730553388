@import "./src/feature/login/style/login.scss";
@import "./src/common/components/loader.scss";
@import "./src/feature/warehouse/components/siteBarMenu.scss";
@import "./src/feature/report/components/report.scss";

body {
  background-color: #f5f5f5;
}

.z-index-top {
  z-index: 3000;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
